import { html } from "lit";

export const plusIcon = html`
<svg viewBox="0 0 512 512">
    <use xlink:href="#plus"></use>
</svg>`

export const minusIcon = html`
<svg viewBox="0 0 512 512">
    <use xlink:href="#minus"></use>
</svg>`

export const loadingSpinner = html`
<div class="flexrow justify-center">
  <span class="icon rotating">
    <svg viewBox="0 0 24 24">
        <use xlink:href="#spinner"></use>
    </svg>
  </span>
<div>`



export const cartIcon = (onClick) => html`
  <div aria-label="Warenkorb" class="header-icon" @click=${() => onClick()}>
    <svg viewBox="0 0 512 512">
      <title>Cart</title>
      <circle
        cx="176"
        cy="416"
        r="16"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="20"
      ></circle>
      <circle
        cx="400"
        cy="416"
        r="16"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="20"
      ></circle>
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="20"
        d="M48 80h64l48 272h256"
      ></path>
      <path
        d="M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="20"
      ></path>
    </svg>
  </div>
`;

export const closeIcon = (onClick, large = false) => {
  const iconClass = large ? ' large' : '';
  return html`
  <button aria-label="schliessen" class="has-icon gap-l-auto" @click=${() => onClick()}>
    <span class="icon${iconClass}">
      <svg viewBox="0 0 512 512">
        <use xlink:href="#close"></use>
      </svg>
    </span>
  </button>
`;
}

export const trashIcon = html`
<svg viewBox="0 0 512 512">
    <use xlink:href="#trash-icon"></use>
</svg>`

export const moreBtton = (url, title, disabled = false, classname = "more") => {
  const buttoClass = `btn has-icon ${classname}`;
  const inner = html`
  <span class="icon">
    <svg viewBox="0 0 96 48">
      <use xlink:href="#arrow-right"></use>
    </svg>
  </span>
  <span>${title}</span>`
  if (disabled) {
    return html`
    <span class="${buttoClass} disabled">
      ${inner}
    </a>`;
  } else {
    return html`
    <a href=${url} class="${buttoClass}">
      ${inner}
    </a>`;
  }
};
