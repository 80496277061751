class AccordionToggle {
  constructor(target, id, notify) {
    this.container = target;
    this.header = target.querySelector('[data-accordion="header"]');
    this.body = target.querySelector('[data-accordion="body"]');
    this.inner = target.querySelector('[data-accordion="body"] > *:first-child');
    this.id = id;
    this.open = false;
    this.header.addEventListener("click", () => notify(this.open ? -1 : this.id))
  }

  animate(height) {
    this.body.style.height = `${height}px`
  }

  toggle(activeId) {
    if (this.id === activeId) {
      if (!this.active) {
        this.container.classList.add("open")
      }
      this.open = true;
      this.animate(this.inner.offsetHeight)
    } else {
      if (this.open) {
        this.container.classList.add("closing")
      }
      this.container.classList.remove("open")

      setTimeout(() => {
        this.container.classList.remove("closing")
      }, "400")
      this.open = false;
      this.animate(0)
    }
  }
}

class Accordion {
  constructor(target) {
    this.target = target;
    this.active = null;
    this.containers = [...target.querySelectorAll('[data-accordion="container"]')].map(((elm, i) => {
      return new AccordionToggle(elm, i, (id) => this.notify(id))
    }));
  }

  notify(id) {
    this.containers.forEach(c => {
      c.toggle(id)
    })
  }
}

window.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('[data-accordion="list"]').forEach((elm) => {
    new Accordion(elm)
  });
});
