import { html, LitElement } from "lit";
import { minusIcon, plusIcon } from "./icons.js";
import { useFetchJson, formattedAmount } from "./utils";

export class ProductCounter extends LitElement {
  static get properties() {
    return {
      value: Number,
      min: Number,
      max: Number,
      item: Number,
      loading: Boolean,
      total: Number,
      target: String,
    };
  }

  constructor() {
    super();
    this.item = this.getAttribute("item");
    this.value = parseInt(this.getAttribute("value"));
    this.min = parseInt(this.getAttribute("min")) || 0;
    this.max = parseInt(this.getAttribute("max"));
    this.error = false;
    this.loading = false;
  }

  createRenderRoot() {
    return this;
  }

  async toggleCount(change) {
    const count = parseInt(this.value) + parseInt(change);
    if (this.max && this.max < count) {
      return;
    } else {
      this.loading = true;
      const response = await useFetchJson(
        `/api/order_items/${this.item}`,
        { amount: count },
        "PUT"
      );
      const { message, item } = response.update;
      const dispatchEvent = new CustomEvent("update", {
        detail: {
          id: this.item,
          response: response,
        },
      });
      this.dispatchEvent(dispatchEvent);
      this.error = message;
      this.value = item ? item.amount : 0;
      this.loading = false;
    }
  }

  render() {
    const plusDiabled = this.loading || (this.max && this.max <= this.value);
    return html` <div class="stack-sm">
      <div class="product-counter">
        <div
          class="toggle${this.loading ? " disabled" : ""}"
          @click=${() => this.toggleCount(-1)}
        >
          <span class="icon"> ${minusIcon} </span>
        </div>
        <input
          class="input"
          type="number"
          min="${this.min}"
          ${this.max ? ` max="${this.max}"` : null}
          value="${this.value}"
        />
        <div
          class="toggle ${plusDiabled ? " disabled" : ""}"
          @click=${() => (plusDiabled ? null : this.toggleCount(1))}
        >
          <span class="icon"> ${plusIcon} </span>
        </div>
      </div>
      ${this.error ? html` <div class="help">${this.error}</div>` : null}
    </div>`;
  }
}

window.customElements.define("product-counter", ProductCounter);
