const defaultErrorText =
  "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
const authToken = document.querySelector("meta[name='csrf-token']").content;

const createNotification = (message, style) => {
  const div = document.createElement("div");
  div.classList.add("alert", style);
  div.innerHTML = message;
  document.querySelector("body").append(div);
}

export function handleError(error) {
  const { message, redirect } = error;
  const text = message || defaultErrorText;
  if (redirect) {
    window.location.href = redirect;
  } else {
    createNotification(text, "error")
  }
}

export function handleSuccessMessage(message) {
  createNotification(message, "success")
}

export async function useFetchJson(url = "", data = false, method = "POST") {
  let request = {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };
  if (data) {
    request = {
      ...request,
      body: JSON.stringify({
        ...data,
        authenticity_token: authToken,
      }),
    };
  }
  return fetch(url, request)
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const message = data.error || data.message || response.statusText || "Ein Fehler ist aufgetreten."
        handleError({ message });
      } else {
        return data;
      }
    })
    .catch((error) => {
      handleError({ message: error });
    });
}

export const shoppingCartItemStock = (item) => {
  let stock;
  if (item.product_variation) {
    stock = item.product_variation.stock || item.stock;
  } else {
    stock = item.stock;
  }
  return stock;
};

export const formattedAmount = (value, currency) => {
  return `${currency} ${value.toFixed(2)}`;
};

export const formattedDiscountValue = (value, currency) => {
  return `- ${formattedAmount(value, currency)}`;
};

export const formattedDiscountRate = (rate) => {
  return `-${(rate * 100).toFixed(1)}%`;
};
