import { html, LitElement } from "lit";
import {
  useFetchJson,
  formattedAmount,
  formattedDiscountValue,
  formattedDiscountRate,
  shoppingCartItemStock,
} from "./utils";
import { trashIcon } from "./icons";
import "./product_counter.js";
import { moreBtton, loadingSpinner } from "./icons.js";

const itemTitle = (item) => html`
<div class="flexcolumn">
  <span>${item.product.title} (${item.amount}x)</span>
  ${item.product_variation
    ? html`<span class="font-small">${item.product_variation.title}</span>`
    : null}
</div>`;

const interactiveCartItemRow = (
  item,
  currency,
  updateCart,
  removeItem,
  isModal
) => {
  const classnames = "stack-sm inset vertical border-bottom";
  if (item.fadeOut) {
    return html` <div class="${classnames} fade-out">${item.message}</div> `;
  } else {
    const stock = shoppingCartItemStock(item);
    return html`
      <div class="${classnames}">
        ${itemTitle(item)}
        <div class="flexrow flex-gap">
          <div class="grow flexrow flex-gap">
            <div class="btn small ghost has-icon" @click=${() => removeItem()}>
              <span class="icon"> ${trashIcon} </span>
              ${isModal ? null : html`<span>Entfernen</span>`}
            </div>
            <product-counter
              item="${item.id}"
              value="${item.amount}"
              min="0"
              max="${stock || null}"
              @update=${updateCart}
            ></product-counter>
          </div>
          <div>${formattedAmount(item.total, currency)}</div>
        </div>
      </div>
    `;
  }
};

const staticCartItemRow = (item, currency) => {
  return html`
    <div class="flexrow space-between inset-tight bottom border-bottom">
      <div>${itemTitle(item)}</div>
      <div class="text-align-right">${formattedAmount(item.total, currency)}</div>
    </tr>
  `;
};

const cartDiscounts = (discounts, currency) => {
  return html`
    ${discounts.items.map((discount) => {
    return html`
        <div class="flexrow space-between">
          <div>${discount.label} (${formattedDiscountRate(discount.rate)})</div>
          <div class="text-align-right">
            ${formattedDiscountValue(discount.value, currency)}
          </div>
        </div>
      `;
  })}
  `;
};

class CartOverview extends LitElement {
  static get properties() {
    return {
      currency: String,
      discounts: Array,
      discounted_total: Number,
      empty: Boolean,
      i18n: Object,
      items: Array,
      loadingId: Number,
      loading: Boolean,
      present: Boolean,
      total: Number,
      vat_amount: Number,
    };
  }

  constructor() {
    super();
    this.loadingId = null;
    this.loading = false;
    this.empty = false;
    this.discounts = false;
    this.items = [];
    this.active = false;
    this.modal = Boolean(this.getAttribute("modal"));
    this.payment = Boolean(this.getAttribute("payment"));
    this.layout = this.defineLayout();
    this.loadData();
  }

  createRenderRoot() {
    return this;
  }

  defineLayout() {
    if (this.modal) {
      return "modal";
    } else if (this.payment) {
      return "payment";
    } else {
      return "overview";
    }
  }

  async loadData() {
    this.loading = true;
    const response = await useFetchJson("/api/orders", false, "GET");
    this.updateValues(response);
    this.loading = false;
  }

  updateValues(response) {
    const { currency, i18n, shopping_cart, present } = response;
    this.present = present;
    this.items = shopping_cart?.items || [];
    this.total = shopping_cart?.total || 0;
    this.shipping_cost = shopping_cart?.shipping_cost || 0;
    this.total_with_shipping = shopping_cart?.total_with_shipping || 0;
    this.discounted_total = shopping_cart?.discounted_total || this.total;
    this.discounts =
      shopping_cart.discounts && shopping_cart.discounts.items.length
        ? shopping_cart.discounts
        : false;
    this.vat_amount = shopping_cart?.vat_amount || 0;
    this.discounted_vat_amount =
      shopping_cart?.discounted_vat_amount || this.vat_amount;
    this.empty = shopping_cart?.empty;
    this.i18n = i18n;
    this.currency = currency;
    const counter = document.getElementById("item-count");
    if (counter) {
      counter.innerHTML = this.items.length;
    }
  }

  updateCart(e) {
    const { id, response } = e.detail;
    this.updateValues(response);
    this.requestUpdate();
  }

  async removeItem(id) {
    if (this.loadingId) {
      return;
    } else {
      this.loadingId = id;
      const response = await useFetchJson(
        `/api/order_items/${id}`,
        {},
        "DELETE"
      );
      this.updateValues(response);
      this.loadingId = null;
    }
  }

  renderShippingCost(shipping_cost) {
    return html`
    <div>
      <div class="flexrow space-between">
        <span>${this.i18n.shipping_cost}</span>
        <span> ${formattedAmount(shipping_cost, this.currency)} </span>
      </div>
    </div>
  `
  }

  renderTotal(total_with_shipping) {
    return html`
        <div class="bold flexrow space-between">
          <span>Total</span>
          <span>${formattedAmount(total_with_shipping, this.currency)}</span>
        </div>
        <span class="color-grey-dark text-align-right">
          ${this.i18n.shipping_method_hint}
        </span>
    `;
  }

  renderCartTotal(amount, vat_amount) {
    return html`
      <div class="flexcolumn align-flex-end">
        <div class="flexrow">
          <span> ${formattedAmount(amount, this.currency)} </span>
        </div>
        <div class="font-small">
          ${this.i18n.incl_vat} ${formattedAmount(vat_amount, this.currency)}
        </div>
      </div>
    `;
  }

  cartTable() {
    let itemsHtml;
    if (this.layout === "payment") {
      itemsHtml = this.items.map((item) => staticCartItemRow(item, this.currency));
    } else {
      itemsHtml = html`<div>${this.items.map((item) =>
        interactiveCartItemRow(
          item,
          this.currency,
          this.updateCart,
          () => this.removeItem(item.id),
          this.modal
        )
      )}</div>`
    }
    return html`
    <div class="stack-sm">
      ${itemsHtml}
      ${this.discounts ? cartDiscounts(this.discounts, this.currency) : null}
      ${this.renderCartTotal(this.discounted_total, this.discounted_vat_amount)}
      </div>
  `;
  }

  renderDiscounts() {
    if (this.discounts) {
      return html`
        <table>
          <tbody>
            <tr>
              ${this.i18n.discounts}
            </tr>
          </tbody>
        </table>
      `
    } else {
      return null
    }
  }

  renderCartContent() {
    if (this.empty || this.items.length === 0) {
      return html`<div><p>${this.i18n.empty_msg}</p></div>`;
    } else {
      let button = false;
      switch (this.layout) {
        case "modal":
          button = moreBtton(
            this.i18n.checkout_path,
            this.i18n.checkout_link_text,
            this.empty,
            "more"
          );
          break;
        case "overview":
          button = moreBtton(
            this.i18n.addresses_path,
            this.i18n.addresses_link_text,
            this.empty,
            "border"
          );
          break;
      }

      return html`<div class="flexcolumn stack">
        ${this.cartTable()}
        ${this.renderShippingCost(this.shipping_cost)}
        ${this.renderTotal(this.total_with_shipping)}
        ${button
          ? html`<div class="gap text-align-right">${button}</div>`
          : null}
      </div>`;
    }
  }

  render() {
    return this.loading ? loadingSpinner : this.renderCartContent();
  }
}

window.customElements.define("cart-overview", CartOverview);
