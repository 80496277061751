import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { initNavigation } from "./navigation";
import './shopping_cart.js';
import "./checkout.js"
import "./accordion.js"
import { initSearchForm } from "./search";
import { initNoUiSlider } from "./nouislider"
import { initCookieBanner } from "./cookiebanner"
import productToCart from "./product_to_cart"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

initNavigation();
initSearchForm();
initNoUiSlider();
initCookieBanner();
