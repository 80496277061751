class Navigation {
  constructor(target) {
    this.active = false;
    this.target = document.getElementById(target);
  }

  handleChange(active) {
    this.active = active;
    this.target.classList.toggle("active");
    const body = document.querySelector("body");
    if (active) {
      body.dataset.noscroll = "true"
    } else {
      delete body.dataset.noscroll
    }
  }
}

export const initNavigation = () => {
  // turbolinks:load
  document.addEventListener('DOMContentLoaded', () => {
    const trigger = document.getElementById("toggle-menu");
    const nav = new Navigation("toggled-menu");
    trigger.addEventListener("change", (event) => {
      nav.handleChange(event.target.checked);
    });
  });
};
