import { useFetchJson } from "./utils";

class ProductToCart {
  constructor(target) {
    this.target = target;
    this.options = target.querySelector("[name='product_variation']");
    this.optionId = this.options ? this.options.value : false;
    this.total = target.querySelector("[data-total]");
    this.productId = this.target.dataset.id;
    this.init();
  }

  async addToCart() {
    let params = {
      product_id: this.productId,
    };
    if (this.optionId) {
      params.product_variation_id = this.optionId;
    }
    const response = await useFetchJson(`/api/order_items/`, params, "POST");
    const { update } = response;
    console.log(update);
    if (update.event === "updated") {
      document.querySelectorAll("shopping-cart-modal").forEach((elm) => {
        elm.setVisibility(true);
        elm.querySelector("cart-overview").updateValues(response);
      });
    } else {
    }
  }

  init() {
    if (this.options) {
      this.options.addEventListener("change", (e) => {
        const { selectedOptions, value } = e.currentTarget;
        this.optionId = value;
        const price = selectedOptions.item(0).dataset.price;
        this.total.innerHTML = price;
      });
    }

    this.target.addEventListener("submit", (e) => {
      e.preventDefault();
      this.addToCart();
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".product-cart-form").forEach((elm) => {
    new ProductToCart(elm);
  });
});
