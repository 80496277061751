import { useFetchJson, handleError } from "./utils";

class PaymentMethodHandler {
  constructor(target) {
    this.container = target;
    this.toggles = target.querySelectorAll("[data-payment]");
    this.tabItems = target.querySelectorAll("[data-method]");
    this.targetUrl = target.dataset.targetUrl;

    this.toggles.forEach((elm) => {
      elm.addEventListener("click", this.handleClick);
    });
  }

  handleClick = async (event) => {
    useFetchJson(this.targetUrl, {
      order: {
        payment_method: event.currentTarget.dataset.payment,
      },
    })
      .then((data) => {
        // TODO: Return entire cart and pass to overview component
        document.querySelectorAll("cart-overview").forEach((elm) => {
          elm.loadData();
        });
        document
          .querySelectorAll("[data-method], [data-payment]")
          .forEach((elm) => {
            if (
              elm.dataset.method === data.payment_method ||
              elm.dataset.payment === data.payment_method
            ) {
              elm.classList.add("active");
            } else {
              elm.classList.remove("active");
            }
          });
      })
      .catch((e) => {
        handleError({ message: e });
      });
  };
}

const initPaymentMethodToggle = () => {
  const target = document.getElementById("payment-method-select");
  if (target) {
    new PaymentMethodHandler(target);
  }
};

const termsSelector = "terms_and_conditions-paypal";

const initPayPal = () => {
  if (document.querySelector("#paypal-button-container")) {
    paypal
      .Buttons({
        createOrder: async function (data, actions) {
          const response = await useFetchJson(
            "/checkout/paypal/create",
            {},
            "POST"
          )
            .then((data) => {
              return data;
            })
            .catch((e) => {
              handleError({ message: e });
            });
          return response.table.id;
        },
        onApprove: async function (data, actions) {
          return actions.order.capture().then((details) => {
            return useFetchJson("/checkout/paypal/submit", {
              paypal: {
                data,
                details,
              },
              terms_and_conditions:
                document.getElementById(termsSelector).checked,
            })
              .then((res) => {
                window.location.replace(res.redirect_path);
              })
              .catch((e) => {
                console.error(e);
              });
          });
        },
        onInit: function (data, actions) {
          actions.disable();
          document
            .getElementById(termsSelector)
            .addEventListener("change", function (e) {
              if (e.target.checked) {
                actions.enable();
              } else {
                actions.disable();
              }
            });
        },
        onClick: function () {
          if (!document.getElementById(termsSelector).checked) {
            document
              .getElementById("paypal-terms-error")
              .classList.remove("hidden");
          }
        },
        onError: function (err) {
          // console.error(err)
        },
      })
      .render("#paypal-button-container");
  }
};

// Addresses toggle
const toggleInputDisabled = (nodes, disabled) => {
  for (let i = 0; i < nodes.length; i++) {
    nodes[i].disabled = disabled;
  }
};

export const initAddressToggle = () => {
  const target = document.getElementById("order_identical_billing_address");
  if (target) {
    target.addEventListener("change", (event) => {
      const nodes = document.querySelectorAll(
        '[name*="[billing_address_attributes]"'
      );
      if (event.currentTarget.checked) {
        toggleInputDisabled(nodes, true);
      } else {
        toggleInputDisabled(nodes, false);
      }
    });
  }
};

document.addEventListener("DOMContentLoaded", function () {
  initAddressToggle();
  initPaymentMethodToggle();
  initPayPal();
});
