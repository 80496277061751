
import { html, LitElement } from "lit";

const cookieName = "user_cookie_consent";
const tagName = "cookie-banner"
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function deleteCookie(cname) {
    const d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=;" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

function acceptCookieConsent() {
    deleteCookie(cookieName);
    setCookie(cookieName, 1, 30);
}

class CookieBanner extends LitElement {
    constructor() {
        super();
        this.value = parseInt(this.getAttribute('value'));
    }

    createRenderRoot() {
        return this;
    }

    acceptHandler() {
        acceptCookieConsent();
        document.querySelectorAll(tagName).forEach(elm => elm.remove())
    }

    declineHandler() {
        window.history.back()
    }

    render() {
        return html`
        <div class="cookie-banner">
            <div class="box bg-white stack">
                <p>Wir verwenden Cookies zur Unterstürzung der Benutzerfreundlichkeit und Funktionalität der Website. Wenn Sie auf der Website weitersurfen, stimmen Sie der Cookie-Nutzung zu.</p>
                <div class="flexrow flex-gap space-between md-columns">
                    <div>
                        <a class="undecorated" href="/datenschutzerklaerung">Mehr Infos zu unserer Datenschutzerklärung</a>
                    </div>
                    <div class="flexrow flexwrap space-between">
                        <button class="btn submit" @click=${() => this.acceptHandler()}>Ich stimme zu</button>
                        <button class="btn" @click=${() => this.declineHandler()}>Ich lehne ab</button>
                    </div>
                </div>
            </div>
        </div>`;
    }
}

window.customElements.define(tagName, CookieBanner);

export function initCookieBanner() {
    window.addEventListener("DOMContentLoaded", function () {
        const accepted = getCookie(cookieName);
        if (!accepted) {
            const banner = document.createElement(tagName);
            document.body.appendChild(banner);
        }
    });
}
