import { html, LitElement } from "lit";
import './product_counter.js';
import { cartIcon, closeIcon, moreBtton } from "./icons.js"

class ShoppingCartModal extends LitElement {
  static get properties() {
    return {
      active: Boolean,
    };
  }

  constructor() {
    super();
    this.active = false;
  }

  createRenderRoot() {
    return this;
  }

  setVisibility(state) {
    this.active = state
    if (state) {
      [...document.getElementsByTagName('search-form')].forEach((elm) => {
        elm.setVisibility(false)
      })
    }
  }

  toggleVisibility() {
    this.setVisibility(!this.active)
  }

  render() {
    const modal =
      html`
        <div class="relative">
          <div
            class="cart-item-count aspect-ratio square"
            @click=${() => this.toggleVisibility()}
            >
            <span id="item-count"></span>
            </div>
            <div class="cart-modal${this.active ? " active" : ""}">
              <div class="border bg-white inset flexcolumn">
                ${closeIcon(() => this.active = false, true)}
                <cart-overview modal="true"></cart-overview>
              </div>
            </div>
          </div>`;

    return html`${cartIcon(() => this.toggleVisibility())} ${modal}`;
  }
}

window.customElements.define("shopping-cart-modal", ShoppingCartModal);
