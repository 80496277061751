import { html, LitElement } from "lit";
import { useFetchJson } from "./utils";

const resultRowItem = (title, url) => {
  return html`<a href="${url}">${title}</a>`;
};

const resultRow = ({ items, title }) => {
  return items.length
    ? html`<div>
          <h3 class="subsubheading">${title}</h3>
          <div class="flexcolumn stack-sm">
           ${items.map(({ title, url }) => resultRowItem(title, url))}
           </div>`
    : null;
};

const searchResults = ({ no_results_text, items }) => {
  const content =
    items.length <= 0
      ? html`<p>${no_results_text}</p>`
      : html`
    <div class="search-results stack undecorated">
      ${items.map(resultRow)}
    </div>
    `;
  return content;
};

export class SearchForm extends LitElement {
  static get properties() {
    return {
      active: Boolean,
      data: Object,
      placeholder: String,
      toggler: String,
    };
  }

  constructor() {
    super();
    this.active = false;
    this.apiUrl = "/api/search";
    this.timeoutAmount = 300;
    this.timeoutID = undefined;
    this.data = undefined;
    this.placeholder = this.getAttribute('placeholder');
    this.toggler = this.getAttribute('toggler');
    this.error = undefined;
    this.listening = false;
    this.toggler = undefined;
  }

  createRenderRoot() {
    return this;
  }

  setVisibility(state) {
    this.active = state;
    if (state) {
      [...document.getElementsByTagName('shopping-cart-modal')].forEach((elm) => {
        elm.setVisibility(false)
      })
    }
  }

  toggleVisibility() {
    this.setVisibility(!this.active);
  }

  async updateSearchValue(e) {
    if (typeof this.timeoutID === "number") {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = setTimeout(
      async function () {
        if (e.target.value !== "") {
          const response = await useFetchJson(
            this.apiUrl,
            {
              search: e.target.value,
            },
            "POST"
          );
          this.data = response.data;
        } else {
          this.data = undefined;
        }
      }.bind(this),
      this.timeoutAmount
    );
  }

  listen() {
    const elm = document.getElementById(this.toggler);
    if (elm) {
      elm.addEventListener("click", () => this.toggleVisibility())
    }
    this.listening = true;
  }

  render() {
    if (!this.listening && this.toggler) {
      this.listen();
    }
    return html`
      <div class="inner-search border-bottom safety-margin${this.active ? " active" : ""}">
        <div class="container width-md inset vertical">
          <div class="grid-columns-2 grid-gap-lg">
            <div>
              <input
                class="input"
                placeholder="${this.placeholder}"
                type="text"
                name="search"
                id="search-input-field"
                @keydown=${(e) => this.updateSearchValue(e)}
              />
            </div>
            ${this.data ? searchResults(this.data) : null}
          </div>
        </div>
      </div>
      `;
  }
}

export const initSearchForm = () => {
  window.customElements.define("search-form", SearchForm);
};
