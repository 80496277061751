import * as noUiSlider from "nouislider";
import styles from "../../../node_modules/nouislider/dist/nouislider.css"

const equalObjects = (obj1, obj2) => {
    let changed = true;
    for (const [key, value] of Object.entries(obj1)) {
        if (obj2[key] !== value) {
            changed = false;
        };
    }
    return changed
}

const getFormValues = (form, targets) => {
    let values = {}
    if (form) {
        targets.forEach(target => {
            const field = form.querySelector(`[name="${target}"]`);
            if (field) {
                values[target] = field.value;
            }
        })
    }
    return values
}

export const initNoUiSlider = () => {
    document.addEventListener("DOMContentLoaded", function () {
        const observedFields = ["product_category_id", "product_brand_id"]
        document.querySelectorAll(".range-slider").forEach(slider => {
            const { min, max, name, selectedMin, selectedMax } = slider.dataset;
            const originalValues = getFormValues(slider.closest("form"), observedFields)
            const minInput = document.createElement('input');
            const maxInput = document.createElement('input');
            [{ e: minInput, key: "min" }, { e: maxInput, key: "max" }].forEach(({ e, key }) => {
                e.style.display = "none";
                e.setAttribute("name", `${name}_${key}`)
                slider.appendChild(e)
            })
            noUiSlider.create(slider, {
                start: [0, parseFloat(max)],
                handleAttributes: [
                    { 'aria-label': 'Preis von' },
                    { 'aria-label': 'Preis bis' },
                ],
                connect: true,
                range: {
                    min: parseFloat(min || 0.0),
                    max: parseFloat(max || 0.0)
                },
                tooltips: true,
            });
            slider.noUiSlider.set([selectedMin || null, selectedMax || null])
            let form = slider.closest("form");

            if (form) {
                form.addEventListener("submit", (e) => {
                    if (equalObjects(originalValues, getFormValues(form, observedFields))) {
                        const sliderValues = slider.noUiSlider.get();
                        minInput.value = sliderValues[0]
                        maxInput.value = sliderValues[1]
                    } else {
                        minInput.remove()
                        maxInput.remove()
                    }
                })
            }
        })
    })
};
